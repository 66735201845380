export const userRoles = {
  admin: 1,
  investigator: 2,
  PAS: 3,
};

export const investigatorRoles = {
  ip: 1,
  pdi: 2,
  postdocsYPif: 3,
  administracion: 4,
};
