import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

//datepicker
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

//ckeditor
import { SwalError } from "../../../../utils/constants";
import { paths } from "../../../../utils/paths";
import { validate } from "./validations";
import Swal from "sweetalert2";
import {
  create,
  getById,
  saveFile,
  update,
  getAll
} from "../../../../utils/requests/dynamicReq";
import { ROUTES } from "../../../../utils/routes";
import Axios from "axios";
import { FileUploader } from "../../../../components/FileUploader/FileUploader";
import { PdfUploader } from "../../../../components/FileUploader/PdfUploader";
import { useDelete } from "../../../../utils/hooks/useDelete";
import { userRoles } from "../../../../utils/roles";
import produce from "immer";


export const ManageTeachingResources = () => {
  const { TEACHING_RESOURCE, SUBJECTS } = paths;

  const { id } = useParams();

  const { userRoleId, isAdmin } = useSelector(({ user }) => user);
  const { investigator } = userRoles;
  const { deleteIt } = useDelete();

  const history = useHistory();

  const [isCreate, setIsCreate] = useState(false);

  const [subjects, setSubjects] = useState([]);
  const [chosenSubjects, setChosenSubjects] = useState([]);

  //userForm
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [pdf, setPdf] = useState();
  const [image, setImage] = useState();

  const [message, setMessage] = useState("");

  const isMounted = useRef(false);
  const source = Axios.CancelToken.source();

  useEffect(() => {
    isMounted.current = true;
    const { pathname } = history.location;
    if(pathname.includes("crear"))
    {
      setIsCreate(true)
      loadStaticData()
    }else loadData();
    return () => {
      isMounted.current = false;
      source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadStaticData = async () => {
    try {
      let response = await getAll(SUBJECTS, source);
      isMounted.current && setSubjects(response);
    } catch (error) {
      console.log("HTTP call cancelled");
    }
  };

  const loadData = async () => {
    try {
      let response = await getById(TEACHING_RESOURCE, id, source);
      let subjects = await getAll(SUBJECTS, source);
      isMounted.current && buildObjToLoad(response, subjects);
    } catch (error) {
      console.log("HTTP call cancelled");
    }
  };
  // prettier-ignore
  const buildObjToLoad = (response, subjects) => {
    const {
      name,
      url,
      pdf,
      image
    } = response;

    setName(name);
    setTimeout(() => {
    }, 200);

    setUrl(url);
    setPdf(pdf);
    setImage(image);


    setSubjects(subjects);
    setChosenSubjects(response.subjects)

    if (isMounted.current) {
      newSubjects(response.subjects, subjects);
    }

  };

  const newSubjects = (subjects, allSubjects) => {
    // eslint-disable-next-line array-callback-return
    subjects.map((r) => {
      setSubjects((state) =>
        produce(state, (drafState) => drafState.filter(({ id }) => id !== r.id))
      );
    });
  };


   // HANDLE SUBJECTS
   const addSubject = (subject) => {
    subject = JSON.parse(subject);
        setChosenSubjects((state) =>
          produce(state, (drafState) => {
            drafState.push(subject);
          })
        )

    //remove subject from list
    setSubjects((state) =>
          produce(state, (drafState) =>
            drafState.filter(({ id }) => id !== subject.id)
          )
        )
  };

  const removeSubject = (subject) => {
    console.log(subject);
    //no need to disable it because edit button is blocked already for non admin and non ips
    // if (isIpUser || isCreate || isAdmin) {
      //remove subject from list
          setChosenSubjects((state) =>
            produce(state, (drafState) =>
              drafState.filter(({ id }) => id !== subject.id)
            )
          )
      // add subject to list
          setSubjects((state) =>
            produce(state, (drafState) => {
              drafState.push(subject);
            })
          )
    // }
  };

//----------------


const selectSubjectsIds = () => {
  let subjectIds = [];
  chosenSubjects.map(({ id }) => subjectIds.push(id));

  return subjectIds;
};

  const handleSubmit = () => {
    const subjectIds = selectSubjectsIds();
    
    const teachingResources = build(subjectIds);

    const notValid = validate(teachingResources);
    if (notValid) {
      setMessage(notValid);
      setTimeout(() => setMessage(""), 4000);
      return;
    }
    let question = isCreate ? "crear un" : "editar este";
    Swal.fire({
      icon: "question",
      text: `¿Quieres ${question} recurso docente?`,
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then(({ isConfirmed }) => {
      isConfirmed && handleConfirmation(teachingResources);
    });
  };

  const handleConfirmation = (teachingResources) => {
    let createOrUpdate = isCreate
      ? create(TEACHING_RESOURCE, teachingResources, source)
      : update(TEACHING_RESOURCE, id, teachingResources, source);
    createOrUpdate
      .then(async ({ id: teachingResourcesId }) => {
        let createOrUpdateText = isCreate ? "creado" : "editado";
        Swal.fire({
          icon: "success",
          text: `Recurso docente ${createOrUpdateText} correctamente.`,
        });
        if (typeof pdf !== "string") {
          const formDataPdf = new FormData();
          formDataPdf.append("pdf", pdf);
          //prettier-ignore
          const response = await saveFile(TEACHING_RESOURCE, teachingResourcesId, "pdf", formDataPdf, source);
          setPdf(response.pdf);
        }
        if (typeof image !== "string") {
          const formData = new FormData();
          formData.append("image", image);
          await saveFile(TEACHING_RESOURCE, teachingResourcesId, "image", formData, source);
        }
        history.push(`${ROUTES.TeachingResources.all}/${teachingResourcesId}`);
        isMounted.current && setIsCreate(false);
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          icon: "error",
          text: SwalError,
        });
      });
  };

  const build = (subjectIds) => ({
    name,
    url,
    subjectIds
  });

  //---------------

  //prettier-ignore
  const editDeleteButtons = () => {
    if (isCreate) {
      return (
        <button className="baseBtn" onClick={handleSubmit}>
          Crear Recurso docente
        </button>
      );
    }
    if ((!isCreate && (isAdmin || userRoleId === investigator))) {
      return (
        <>
          <button className="baseBtn" onClick={handleSubmit}>
            Editar Recurso docente
          </button>
          <button
            className="smallBtn btn-danger ml-1"
            onClick={() => deleteIt(TEACHING_RESOURCE, id, ROUTES.TeachingResources.all, source)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </>
      );
    }
  };
  return (
    <div className="centerPage">
      <div className="container-fluid p-4">
        <div className="row">
          <div className="col d-flex justify-content-between">
            <div>
              <h3>Recurso Docente</h3>
            </div>
            <div>{editDeleteButtons()}</div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12 text-center text-danger">
            <span>{message}</span>
          </div>
        </div>

        <div className="form-row mt-2">
          <div className="col-6 d-flex  justify-content-center">
            <FileUploader handleFile={setImage} image={image} />
          </div>
        </div>

        <div className="form-row mt-2">
          <div className="col-6">
            <small>*Nombre</small>
            <input
              type="text"
              className="form-control"
              placeholder="Nombre"
              value={name}
              onChange={({ target: { value } }) => setName(value)}
            />
          </div>
          <div className="col-6">
          <small>*Enlace</small>
            <input
              type="text"
              className="form-control"
              placeholder="Link"
              value={url}
              onChange={({ target: { value } }) => setUrl(value)}
            />
          </div>
        </div>
        <div className="form-row mt-4">
          <div className="col-6">
            <PdfUploader handleFile={setPdf} pdf={pdf} />
          </div>
        <div className="col-6">
          {/* <small>Seleccionar asignaturas</small> */}
          <select
            className="form-control input"
            //no need to disable it because edit button is blocked already for non admin and non ips
            // disabled={!isIpUser && !isAdmin}
            value={""}
            onChange={({ target: { value } }) => {
              addSubject(value);
            }}
          >
            <option value={""}>Asignaturas</option>
            {subjects?.map((i) => (
              <option key={i.id} value={JSON.stringify(i)}>
                {`${i.nameEs}`}
              </option>
            ))}
          </select>
          {chosenSubjects?.map((subject) => (
            <span
              key={subject.id}
              style={{ cursor: "pointer" }}
              className="badge badge-info badge-blue-color mr-1 mt-3  "
              onClick={() => removeSubject(subject)}
            >
              {`${subject.nameEs}`} X
            </span>
          ))}
        </div>
        </div>

        <div className="row mt-4">
          <div className="col-12 ">
          <div className="d-flex justify-content-center mb-3">
                <h5>-Puede subir un pdf o enlace-</h5>
              </div>
              <div className="d-flex justify-content-center mb-3">
                <h5>-En caso de haber ambos, se priorizará en el orden mencionado-</h5>

              </div>
          </div>
          </div>

      </div>
    </div>
  );
};
