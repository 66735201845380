//middleware
import { Route } from "react-router-dom";

//views
import { ROUTES } from "../utils/routes";
import { Groups } from "../views/Logged/Groups/Groups";
import { ManageGroups } from "../views/Logged/Groups/ManageGroups/ManageGroups";
import { Degree } from "../views/Logged/Degree/Degree";
import { ManageDegrees } from "../views/Logged/Degree/ManageDegrees/ManageDegrees";

const Admin = () => {
  // prettier-ignore
  return (
    <>
      <Route exact path={ROUTES.Groups.all} component={Groups} />
      <Route exact path={[ROUTES.Groups.create, ROUTES.Groups.update]} component={ManageGroups}/>
      <Route exact path={ROUTES.Degree.all} component={Degree} />
      <Route exact path={[ROUTES.Degree.create, ROUTES.Degree.update]} component={ManageDegrees} />
    </>
  );
};

export default Admin;
