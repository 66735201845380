export const separateRoles = (investigators) => {
  let coordinators = investigators
    .filter(({ subjectRole }) => subjectRole === "coordinator")
    .map(({ investigator }) => investigator);
  let participants = investigators
    .filter(({ subjectRole }) => subjectRole === "participant")
    .map(({ investigator }) => investigator);
  return { coordinators: cleanUsers(coordinators), participants: cleanUsers(participants) };
};

export const cleanUsers = (users) => {
  let cleanedUsers = [];
  users.map(({ id, name, surname1, surname2 }) =>
    cleanedUsers.push({ id, name, surname1, surname2 })
  );
  return cleanedUsers;
};

export const uniqueUsers = (set1, set2) =>
  [].concat(
    set1?.filter((set) => set2.every(({ id }) => set.id !== id)),
    set2.filter((set) => set1?.every(({ id }) => set.id !== id))
  );

export const buildInvestigatorSubject = (participants, coordinators, subjectId) => {
  participants = participants.map(({ id }) => ({
    subjectRole: "PARTICIPANT",
    investigatorId: id,
    subjectId,
  }));
  coordinators = coordinators.map(({ id }) => ({
    subjectRole: "COORDINATOR",
    investigatorId: id,
    subjectId,
  }));
  return participants.concat(coordinators);
};
