import { FC, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

//search feature
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Col from "react-bootstrap/esm/Col";
import Form from "react-bootstrap/esm/Form";
import InputGroup from "react-bootstrap/esm/InputGroup";
import { ROUTES } from "../../../utils/routes";
import Axios from "axios";
import { getAll, getById, getByInvestigator } from "../../../utils/requests/dynamicReq";
import { INews } from "../../../interfaces/INews";
import { paths } from "../../../utils/paths";
import { IStore } from "../../../interfaces/IStore";
import { IUser } from "../../../interfaces/IUser";
import { useSelector } from "react-redux";
import { userRoles } from "../../../utils/roles";

export const News: FC = () => {
  let { userRoleId, userId } = useSelector(({ user }: IStore) => user as IUser);

  const { investigator } = userRoles;

  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const isMountedRef = useRef(false);
  const source = Axios.CancelToken.source();
  const { COMMUNICATIONS, COMMUNICATION_USER } = paths;
  const [news, setNews] = useState<INews[]>([]);
  const [displayNews, setDisplayNews] = useState<INews[]>([]);

  useEffect(() => {
    setIsLoading(true);
    isMountedRef.current = true;
    loadData();
    return () => {
      isMountedRef.current = false;
      source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    try {
      //if userRole investigator=> only user news; else=> all news
      const response = (userRoleId === investigator) ? await getByInvestigator(COMMUNICATION_USER, userId, source): await getAll(COMMUNICATIONS, source);
      if (isMountedRef.current) {
        setDisplayNews(response);
        setNews(response);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log("HTTP call cancelled");
    }
  };

  const searchBar = (value: any) => {
    setDisplayNews(
      news.filter(({ titleEs }: any) =>
        titleEs?.toLowerCase().includes(value.toLowerCase())
      )
    );
    !value && setDisplayNews(news);
  };
  
  return (
    <div className="container-fluid p-4">
      <div className="row">
        <div className="col d-flex justify-content-between">
          <div>
            <h3>Noticias</h3>
          </div>
          <div>
          
              <button
                className="baseBtn"
                onClick={() => history.push(ROUTES.News.create)}
              >
                + Añadir
              </button>
              
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <Form.Group as={Col}>
          <InputGroup className="shadow">
            <InputGroup.Prepend>
              <InputGroup.Text className="inputGroup ">
                <FontAwesomeIcon color="#0073B5" icon={faSearch} />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              type="text"
              placeholder="Buscar..."
              className="form-control"
              // value={"IP - " + search}
              onChange={({ target: { value } }) => searchBar(value)}
            />
          </InputGroup>
        </Form.Group>
      </div>
      <div className="row">
        <div className="col-12 ">
          <table className="table table-hover">
            <tbody>
              {displayNews?.map(({ id, titleEs }) => (
                <tr
                  key={id}
                  className="pointer"
                  onClick={() => history.push(`${ROUTES.News.all}/${id}`)}
                >
                  <td>{titleEs}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {isLoading && (
            <div className="d-flex justify-content-center">
              <div className="spinner-border mt-5" role="status" />
            </div>
          )}

          {!news.length && !isLoading && (
            <div className="d-flex justify-content-center mb-3">
              <h5>-No existen noticias-</h5>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
