import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef } from "react";
import { baseURL } from "../../utils/constants";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";

interface IProps {
  handleFile(file: any): void;
  pdf?: string;
}

export const PdfUploader = ({ handleFile, pdf }: IProps) => {
  const inputFile: any = useRef(null);

  const handleChange = (e: any) => {
    const fileUploaded = e.target.files[0];
    if (fileUploaded.size > 15000000) {
      Swal.fire({
        icon: "error",
        text: "Pdf demasiado grande, asegúrese de subir una menor a 1.5mb",
      });
      return;
    }
    handleFile(fileUploaded);
  };

  return (
    <>
      <input
        type="text"
        style={{ cursor: "pointer" }}
        className="form-control"
        placeholder="Suba un PDF asociado"
        onClick={() => inputFile.current?.click()}
      />
      <input
        type="file"
        ref={inputFile}
        style={{ display: "none" }}
        id="file"
        accept=".pdf,.doc"
        onChange={handleChange}
      />
      {!!(typeof pdf === "string") && (
        <a
          href={baseURL + pdf}
          download={baseURL + pdf}
          target="_blank"
          rel="noreferrer"
        >
          {pdf?.split("documents")[1]}
        </a>
      )}
      {!!(pdf && typeof pdf !== "string") && (
        <div className="d-flex mt-1">
          <FontAwesomeIcon icon={faCheck} />
          <span className="ml-1">Pdf listo para subir</span>
        </div>
      )}
    </>
  );
};
