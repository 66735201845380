import React, { FC } from "react";

//middleware
import { useSelector } from "react-redux";
import { Route } from "react-router-dom";

//interfaces
import { IStore } from "../interfaces/IStore";
import { IUser } from "../interfaces/IUser";

//views
import { Sidebar } from "../components/Sidebar/Sidebar";
import { ROUTES } from "../utils/routes";
import { Home } from "../views/Logged/Home/Home";
import { ManageProfile } from "../views/Logged/ManageProfile/ManageProfile";
import { userRoles } from "../utils/roles";
import Admin from "./admin";
import Investigator from "./investigator";
import Pas from "./pas";
import { News } from "../views/Logged/News/News";
import { ManageNews } from "../views/Logged/News/ManageNews/ManageNews";
import { ManagePublications } from "../views/Logged/Publications/ManagePublications/ManagePublications";
import { Publications } from "../views/Logged/Publications/Publications";


const LoggedRoutes: FC = () => {
  //prettier-ignore
  let { token, userRoleId, isAdmin } = useSelector(({ user }: IStore) => user as IUser);

  const isInvestigator = token && userRoleId === userRoles.investigator;
  const isPAS = token && (userRoleId === userRoles.PAS);

  return (
    <>
      <Sidebar />
      <Route exact path="/" component={Home} />
      <Route exact path={ROUTES.ManageProfile} component={ManageProfile} />

      <Route exact path={ROUTES.News.all} component={News} />
      <Route exact path={[ROUTES.News.create, ROUTES.News.update]} component={ManageNews}/>

      <Route exact path={ROUTES.Publications.all} component={Publications} />
      <Route
        exact
        path={[ROUTES.Publications.create, ROUTES.Publications.update]}
        component={ManagePublications}
      />

      {isAdmin && <Admin />}
      {(isAdmin || isInvestigator) && <Investigator />}
      {(isAdmin || isPAS) && <Pas />}
    </>
  );
};

export default LoggedRoutes;
