import { IInvestigator } from "../../../../interfaces/IInvestigator";
import { publication } from "../../../../utils/publicationType";

export const validate = (pb:any, authorsId: IInvestigator[]) => {
      if (!authorsId.length) return "Por favor, asocie esta publicación a un autor del departamento"
      if (!pb.title) return "Por favor, rellene todos los campos con *"
      if (!pb.date) return "Por favor, rellene todos los campos con *"
      if (!pb.authors) return "Por favor, rellene todos los campos con *"
    //   if (!pb.journal) return "Por favor, rellene todos los campos con *"
      if (pb.publicationType === publication.chapter && !pb.titleChapter) return"Por favor, rellene todos los campos con *"
      if (pb.publicationType === publication.congressCommunication && !pb.congress) return"Por favor, rellene todos los campos con *"
  };
  