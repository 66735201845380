//middleware
import { Route } from "react-router-dom";

//views
import { ROUTES } from "../utils/routes";
import { ManageProject } from "../views/Logged/Projects/ManageProjects/ManageProject";
import { Projects } from "../views/Logged/Projects/Projects";
import { TeachingResources } from "../views/Logged/TeachingResource/TeachingResource";
import { ManageTeachingResources } from "../views/Logged/TeachingResource/ManageTeachingResource/ManageTeachingResource";



const Investigator = () => {
  // prettier-ignore
  return (
    <>
      <Route exact path={ROUTES.Projects.all} component={Projects} />
      <Route exact path={[ROUTES.Projects.create, ROUTES.Projects.update]} component={ManageProject}/>
      <Route exact path={ROUTES.TeachingResources.all} component={TeachingResources} />
      <Route exact path={[ROUTES.TeachingResources.create, ROUTES.TeachingResources.update]} component={ManageTeachingResources}/>

    </>
  );
};

export default Investigator;
