import { FC, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

//search feature
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Col from "react-bootstrap/esm/Col";
import Form from "react-bootstrap/esm/Form";
import InputGroup from "react-bootstrap/esm/InputGroup";
import { ROUTES } from "../../../utils/routes";
import Axios from "axios";
import { paths } from "../../../utils/paths";
import { getAll } from "../../../utils/requests/dynamicReq";

export const TeachingResources: FC = () => {

  const { TEACHING_RESOURCE } = paths;
  const history = useHistory();
  const isMountedRef = useRef(false);
  const source = Axios.CancelToken.source();

  const [isLoading, setIsLoading] = useState(false);
  const [teachingResources, setTeachingResources] = useState([]);
  const [displayTeachingResources, setDisplayTeachingResources] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    isMountedRef.current = true;
    loadData();
    return () => {
      isMountedRef.current = false;
      source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    try {
      const response = await getAll(TEACHING_RESOURCE, source);
      if (isMountedRef.current) {
        setDisplayTeachingResources(response);
        setTeachingResources(response);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log("HTTP call cancelled");
    }
  };

  const searchBar = (value: any) => {
    setDisplayTeachingResources(
      teachingResources.filter(({ name }: any) =>
        name?.toLowerCase().includes(value.toLowerCase())
      )
    );
    !value && setDisplayTeachingResources(teachingResources);
  };

  return (
    <div className="container-fluid p-4 ">
      <div className="row">
        <div className="col d-flex justify-content-between">
          <div>
            <h3>Recursos Docentes</h3>
          </div>
          <div>
           
              <button
                className="baseBtn"
                onClick={() => history.push(ROUTES.TeachingResources.create)}
              >
                + Añadir
              </button>

          </div>
        </div>
      </div>
      <div className="row mt-4">
        <Form.Group as={Col}>
          <InputGroup className="shadow">
            <InputGroup.Prepend>
              <InputGroup.Text className="inputGroup ">
                <FontAwesomeIcon color="#0073B5" icon={faSearch} />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              type="text"
              placeholder="Buscar..."
              className="form-control"
              onChange={({ target: { value } }) => searchBar(value)}
            />
          </InputGroup>
        </Form.Group>
      </div>
      <div className="row ">
        <div className="col-12 ">
          <table className="table table-hover">
            <tbody>
              {displayTeachingResources?.map(({ id, name }) => (
                <tr
                  key={id}
                  className="pointer"
                  onClick={() => history.push(`${ROUTES.TeachingResources.all}/${id}`)}
                >
                  <td>{name}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {isLoading && (
            <div className="d-flex justify-content-center">
              <div className="spinner-border mt-5" role="status" />
            </div>
          )}

          {!teachingResources.length && !isLoading && (
            <div className="d-flex justify-content-center mb-3">
              <h5>-No se encontraron resultados-</h5>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
