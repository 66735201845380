//middleware
import { Route } from "react-router-dom";

//views
import { ROUTES } from "../utils/routes";
import { ManageProfile } from "../views/Logged/ManageProfile/ManageProfile";
import { Users } from "../views/Logged/Users/Users";
import { ManageSubject } from "../views/Logged/Subjects/ManageSubjects/ManageSubject";
import { Subjects } from "../views/Logged/Subjects/Subjects";

const Pas = () => {
  // prettier-ignore
  return (
    <>
    <Route exact path={ROUTES.Users.all} component={Users} />
    <Route exact path={[ROUTES.Users.create, ROUTES.Users.update]} component={ManageProfile} />
    <Route exact path={ROUTES.Subjects.all} component={Subjects} />
    <Route exact path={[ROUTES.Subjects.create, ROUTES.Subjects.update]} component={ManageSubject}/>
    </>
    
  );
};

export default Pas;
