import { useRef, useState } from "react";
import Swal from "sweetalert2";
import uploadImg from "../../assets/images/Upload-PNG-Image-File.png";
import { baseURL } from "../../utils/constants";

interface IProps {
  handleFile(file: any): void;
  image?: string;
}

export const FileUploader = ({ handleFile, image }: IProps) => {
  const inputFile: any = useRef(null);
  const [imgData, setImgData] = useState<any>(null);

  const handleChange = (e: any) => {
    const fileUploaded = e.target.files[0];
    if (fileUploaded.size > 5000000) {
      Swal.fire({
        icon: "error",
        text: "Imágen demasiado grande, asegúrese de subir una menor a 5mb",
      });
      return;
    }

    const reader = new FileReader();
    reader?.addEventListener("load", () => {
      setImgData(reader.result);
    });
    reader?.readAsDataURL(fileUploaded);
    handleFile(fileUploaded);
  };

  return (
    <>
      <div className="col-6 d-flex flex-column align-items-center ">
        {(!image || imgData) && (
          <img
            className="pointer"
            onClick={() => inputFile.current?.click()}
            style={{ width: imgData ? "75%" : "40%" }}
            src={imgData || uploadImg}
            alt=""
          />
        )}
        {typeof image === "string" && !imgData && (
          <img
            className="pointer"
            onClick={() => inputFile.current?.click()}
            style={{ width: "75%" }}
            src={`${baseURL}${image}`}
            alt=""
          />
        )}
        <p onClick={() => inputFile.current?.click()} className="pointer">Suba una imagen</p>
        <input
          type="file"
          accept=".png,.jpg,.gif,.jpeg"
          ref={inputFile}
          style={{ display: "none" }}
          id="file"
          onChange={handleChange}
        />
      </div>
    </>
  );
};
