/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../utils/routes";
import Axios from "axios";
import { getAll } from "../../../utils/requests/dynamicReq";
import { paths } from "../../../utils/paths";

//search feature
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Col from "react-bootstrap/esm/Col";
import Form from "react-bootstrap/esm/Form";
import InputGroup from "react-bootstrap/esm/InputGroup";
import { searchInvestigators } from "../../../utils/auxiliar/investigators";
import { IInvestShort } from "../../../interfaces/IInvestigatorShort";

export const Users: FC = () => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  const isMountedRef = useRef(false);
  const source = Axios.CancelToken.source();
  const { INVESTIGATORS } = paths;
  const [investigators, setInvestigators] = useState<any>([]);
  const [search, setSearch] = useState("");
  const [filteredInvestigators, setFilteredInvestigators] = useState<
    IInvestShort[]
  >([]);

  useEffect(() => {
    setIsLoading(true);
    isMountedRef.current = true;
    loadInvestigators();
    return () => {
      isMountedRef.current = false;
      source.cancel();
    };
  }, []);

  const loadInvestigators = async () => {
    try {
      const response = await getAll(INVESTIGATORS, source);
      if (isMountedRef.current) {
        filterInvestigators(response);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("HTTP call cancelled");
    }
  };

  const filterInvestigators = (rawInvestigators: any[]) => {
    let investigators: IInvestShort[] = rawInvestigators.map(
      ({ id, name, surname1, surname2 }) => ({
        id,
        name,
        surname1,
        surname2: surname2 == null ? "" : surname2,
      })
    );
    sortBySurname1(investigators)
  };

  const sortBySurname1 = (users:IInvestShort[]) => {
    users.sort((a, b) => a.surname1.localeCompare(b.surname1));
    setInvestigators(users);
    setFilteredInvestigators(users);
   
  };

  const handleSearch = (value: any) => {
    setSearch(value);
    setFilteredInvestigators(searchInvestigators(investigators, value));
    !value && setFilteredInvestigators(investigators);
  };

  return (
      <div className="container-fluid p-4">
        <div className="row">
          <div className="col d-flex justify-content-between">
            <div>
              <h3>Usuarios</h3>
            </div>
            <div>
              <button
                className="baseBtn"
                onClick={() => history.push(`${ROUTES.Users.create}`)}
              >
                + Añadir
              </button>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <Form.Group as={Col}>
            <InputGroup className="shadow">
              <InputGroup.Prepend>
                <InputGroup.Text className="inputGroup ">
                  <FontAwesomeIcon color="#0073B5" icon={faSearch} />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                placeholder="Buscar..."
                className="form-control"
                value={search}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </InputGroup>
          </Form.Group>
        </div>
        <div className="row">
          <div className="col-12 ">
            <table className="table table-hover">
              <tbody>
                {filteredInvestigators?.map(
                  ({ id, name, surname1, surname2 }: IInvestShort) => (
                    <tr
                      key={id}
                      className="pointer"
                      onClick={() => history.push(`${ROUTES.Users.all}/${id}`)}
                    >
                      <td>{`${surname1} ${surname2}, ${name}`}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
            {isLoading && (
              <div className="d-flex justify-content-center">
                <div className="spinner-border mt-5" role="status" />
              </div>
            )}

            {!filteredInvestigators.length && !isLoading && (
              <div className="d-flex justify-content-center mb-3">
                <h5>-No existen usuarios-</h5>
              </div>
            )}
          </div>
        </div>
      </div>
  );
};
