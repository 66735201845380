import { ISubject } from "../../../../interfaces/ISubject";

export const validate = (p: ISubject) => {
  if (p.nameEs && p.nameEs.length > 499) return "El nombre no puede tener más de 500 caracteres";
  if (p.nameEn && p.nameEn.length > 499) return "El nombre no puede tener más de 500 caracteres";
  if (p.url && p.url.length > 255) return "La url no puede tener más de 255 caracteres";
  
  if (!p.nameEs) return "Por favor rellene el nombre en Español.";
  if (!p.descriptionEs) return "Por favor rellene el nombre en Español.";

};
