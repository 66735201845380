import { FC, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

//search feature
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Col from "react-bootstrap/esm/Col";
import Form from "react-bootstrap/esm/Form";
import InputGroup from "react-bootstrap/esm/InputGroup";
import { ROUTES } from "../../../utils/routes";
import Axios from "axios";
import { getAll, getByInvestigator } from "../../../utils/requests/dynamicReq";
import { paths } from "../../../utils/paths";
import parse from "html-react-parser";
import { useSelector } from "react-redux";
import { IStore } from "../../../interfaces/IStore";
import { IUser } from "../../../interfaces/IUser";
import { IPublication } from "../../../interfaces/IPublication";
import moment from "moment";

export const Publications: FC = () => {
  let { investigatorId, isAdmin } = useSelector(({ user }: IStore) => user as IUser);

  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const isMountedRef = useRef(false);
  const source = Axios.CancelToken.source();
  const { PUBLICATIONS } = paths;
  const [publications, setPublications] = useState<IPublication[]>([]);
  const [displayPublications, setDisplayPublications] = useState<IPublication[]>([]);

  useEffect(() => {
    setIsLoading(true);
    isMountedRef.current = true;
    loadData();
    return () => {
      isMountedRef.current = false;
      source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    try {
      const response = isAdmin
        ? await getAll(PUBLICATIONS, source)
        : await getByInvestigator(
            `${PUBLICATIONS}/investigator`,
            investigatorId,
            source
          );
      if (isMountedRef.current) {
        setPublications(response);
        setDisplayPublications(response);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("HTTP call cancelled");
    }
  };

  const searchBar = (value: any) => {
    setDisplayPublications(
      publications.filter(({ title }: any) =>
        title.toLowerCase().includes(value.toLowerCase())
      )
    );
    !value && setDisplayPublications(publications);
  };

  return (
      <div className="container-fluid p-4">
        <div className="row">
          <div className="col d-flex justify-content-between">
            <div>
              <h3>Publicaciones</h3>
            </div>
            <div>
              <button
                className="baseBtn"
                onClick={() => history.push(ROUTES.Publications.create)}
              >
                + Añadir
              </button>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <Form.Group as={Col}>
            <InputGroup className="shadow">
              <InputGroup.Prepend>
                <InputGroup.Text className="inputGroup ">
                  <FontAwesomeIcon color="#0073B5" icon={faSearch} />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                placeholder="Buscar..."
                className="form-control"
                onChange={({ target: { value } }) => searchBar(value)}
              />
            </InputGroup>
          </Form.Group>
        </div>
        <div className="row">
          <div className="col-12 ">
            <table className="table table-hover">
              <tbody>
                {displayPublications?.map(
                  ({ id, title, authors, date, titleChapter }) => (
                    <tr
                      key={id}
                      className="pointer"
                      onClick={() =>
                        history.push(`${ROUTES.Publications.all}/${id}`)
                      }
                    >
                      <td>
                        {titleChapter ? (
                          <>
                            <h6>{parse(titleChapter)}</h6>
                            <h6 className="text-secondary">{parse(title)}</h6>
                          </>
                        ) : (
                          <h6>{parse(title)}</h6>
                        )}
                        <span>
                          {authors} {","} {moment(date).locale("es").format("YYYY")}
                        </span>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
            {isLoading && (
              <div className="d-flex justify-content-center">
                <div className="spinner-border mt-5" role="status" />
              </div>
            )}

            {!displayPublications.length && !isLoading && (
              <div className="d-flex justify-content-center mb-3">
                <h5>-No hay publicaciones-</h5>
              </div>
            )}
          </div>
        </div>
      </div>
  );
};
