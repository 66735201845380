export const paths = {
  COMMUNICATIONS: "communications",
  COMMUNICATION_USER: "communications/user",
  INVESTIGATORS: "investigators",
  GROUPS: "investigation-groups",
  INVESTROLE: "investigator-roles",
  USERROLE: "user-roles",
  USERS: "users",
  COMMTYPES: "communication-types",
  PROJECTS: "projects",
  investigatorPROJECT: "investigator/project",
  SUBJECTS: "subjects",
  INVESTIGATOR_SUBJECT: "investigator/subject",
  SEMINARS: "",
  AREAS: "areas",
  newUsers: "investigators/users",
  TEACHING_RESOURCE: "teaching-resources",
  TEACHING_RESOURCEDOCUMENTS: "teachingResources-documents/teachingResources",
  investigatorGROUP: "investigators/investigation-group",
  PUBLICATIONS:"publications",
  investigatorPUBLICATION: "investigator/publication",
  DEGREES: "degrees",
  DEGREE_TYPES: "degree-types",
  groupPUBLICATION: "investigation-group/publication",
  publicationsByGROUP: "publications/investigation-group",
  publicationsINVESTIGATOR: "publications/investigator",
  NEWPASSWORD: "auth/change-password",
  groupSelectPUBLICATION:"investigation-group",
  userSelectPUBLICATION:"investigator",
  RESETPASSWORD:"auth/reset-password/user",
};
