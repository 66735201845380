import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

//utils
import {
  create,
  getAll,
  Delete,
  getById,
  update,
} from "../../../../utils/requests/dynamicReq";
import { paths } from "../../../../utils/paths";

//ckeditor
import { SwalError, requiredInputs } from "../../../../utils/constants";

//datepicker
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";

//middleware
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import Axios from "axios";
import { ROUTES } from "../../../../utils/routes";

registerLocale("es", es);

export const ManageDegrees = () => {
  const { DEGREES: TRAININGS, DEGREE_TYPES: TRAININGTYPES } = paths;
  const { id } = useParams();

  let { investigatorId, isAdmin, userId } = useSelector(({ user }) => user);

  const history = useHistory();

  const [isCreate, setIsCreate] = useState(false);

  const [titleEs, setTitleEs] = useState("");
  const [titleEn, setTitleEn] = useState("");
  const [url, setUrl] = useState("");
  const [degreeTypes, setDegreeTypes] = useState([]);
  const [degreeTypeId, setDegreeTypeId] = useState("");
  const [active, setActive] = useState(true);
  const [creatorId, setCreatorId] = useState(null);

  const [message, setMessage] = useState("");

  const isMounted = useRef(false);
  const source = Axios.CancelToken.source();

  useEffect(() => {
    isMounted.current = true;
    const { pathname } = history.location;
    if (pathname.includes("crear")) {
      setIsCreate(true);
    } else {
      setIsCreate(false);
      loadEditData();
    }
    loadDefaultData();
    return () => {
      isMounted.current = false;
      source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadDefaultData = async () => {
    let degreeTypes = await getAll(TRAININGTYPES, source);
    if (isMounted.current) {
      setDegreeTypes(degreeTypes);
    }
  };
  console.log(isCreate);
  const loadEditData = async () => {
    const {
      titleEs,
      titleEn,
      active,
      url,
      degreeType,
    } = await getById(TRAININGS, id, source);
    // let a = await getById(TRAININGS, id, source);
    // console.log(a);

    setTitleEs(titleEs);
    setTitleEn(titleEn);
    setUrl(url);
    setDegreeTypeId(degreeType?.id);
    setActive(active ? 1 : 0);
  };

  // -----------------------

  const handleSubmit = () => {
    const degree = build();
    console.log(degree);
    if (!titleEs || !degreeTypeId) {
      setMessage(requiredInputs);
      setTimeout(() => setMessage(""), 4000);
      return;
    }
    let question = isCreate ? "crear una" : "editar esta";
    Swal.fire({
      icon: "question",
      text: `¿Quieres ${question} titulación?`,
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then(({ isConfirmed }) => {
      isConfirmed && handleConfirmation(degree);
    });
  };

  const handleConfirmation = (degree) => {
    let createOrUpdate = isCreate
      ? create(TRAININGS, degree, source)
      : update(TRAININGS, id, degree, source);
    createOrUpdate
      .then(({ id: degreeId }) => {
        let createOrUpdateText = isCreate ? "creada" : "editada";
        Swal.fire({
          icon: "success",
          text: `Titulación ${createOrUpdateText} correctamente.`,
        });
        history.push(`${ROUTES.Degree.all}/${degreeId}`);
        isMounted.current && setIsCreate(false);
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          text: SwalError,
        });
      });
  };

  const build = () => ({
    titleEs,
    titleEn,
    active: active === 1 ? true : false,
    url,
    degreeTypeId,
  });

  const editDeleteButtons = () => {
    if (isCreate) {
      return (
        <button className="baseBtn" onClick={handleSubmit}>
          Crear Titulación
        </button>
      );
    }
    if ((!isCreate) || isAdmin)  {
      return (
        <>
          <button className="baseBtn" onClick={handleSubmit}>
            Editar Titulación
          </button>
          <button className="smallBtn btn-danger ml-1" onClick={handleDelete}>
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </>
      );
    }
  };

  const handleDelete = () => {
    Swal.fire({
      icon: "question",
      text: `¿Quieres borrar esta titulación?`,
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then(({ isConfirmed }) => {
      isConfirmed &&
        Delete(TRAININGS, id, source)
          .then(({ id }) => {
            Swal.fire({
              icon: "success",
              text: `Titulación borrada correctamente.`,
            });
            history.push(ROUTES.Degree.all);
            setIsCreate(false);
          })
          .catch((error) => {
            console.log(error);
            Swal.fire({
              icon: "error",
              text: "Ha habido un error, por favor intente de nuevo más tarde.",
            });
          });
    });
  };

  return (
    <div className="centerPage">
    <div className="container-fluid p-4">
      <div className="row">
        <div className="col d-flex justify-content-between">
          <div>
            <h3>Titulación</h3>
          </div>
          <div>{editDeleteButtons()}</div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12 text-center text-danger">
          <span>{message}</span>
        </div>
      </div>
      <div className="container-fluid mt-2">
        <div className="form-row mt-4">
          <div className="col">
            <small>*Nombre</small>
            <input
              type="text"
              className="form-control"
              placeholder="Nombre"
              value={titleEs}
              onChange={({ target: { value } }) => setTitleEs(value)}
            />
          </div>
        </div>
        <div className="form-row mt-4">
          <div className="col">
            <small>Link</small>
            <input
              type="text"
              className="form-control"
              placeholder="Link"
              value={url}
              onChange={({ target: { value } }) => setUrl(value)}
            />
          </div>
          <div className="col">
            <small>Tipo</small>
            <select
              className="form-control input"
              value={degreeTypeId}
              onChange={({ target: { value } }) => setDegreeTypeId(+value)}
            >
              <option value={""}>*Tipo</option>
              {degreeTypes?.map(({ id, name }) => (
                <option key={id} value={+id}>
                  {name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="form-row mt-4">
          <div className="col-6">
            <small>*Activo</small>
            <select
              className="form-control input"
              value={active}
              onChange={({ target: { value } }) => setActive(value)}
            >
              <option value={1}>Sí</option>
              <option value={0}>No</option>
            </select>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};
