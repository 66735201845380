import React, { useState } from "react";

//css
import "./Sidebar.css";

//utils
import { LogoutAction } from "../../redux/actions";
import { ROUTES } from "../../utils/routes";
import logoUma from "../../assets/images/logo_blanco_horizontal_chico.png";

//middleware
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Nav } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import {
  faUser,
  faUsers,
  faCoffee,
  faHome,
  faSignOutAlt,
  faBriefcase,
  faBookOpen,
  faNewspaper,
  faPaintBrush,
  faCalendar,
  faBook,
  faPaperclip,
  faHandPaper,
  faChalkboardTeacher,
} from "@fortawesome/free-solid-svg-icons";
import { IStore } from "../../interfaces/IStore";
import { IUser } from "../../interfaces/IUser";
import { userRoles } from "../../utils/roles";

export const Sidebar = () => {
  //prettier-ignore
  let { isAdmin, userRoleId } = useSelector(({ user }: IStore) => user as IUser);

  //MOCK DATA

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const logout = () => {
    setLoading(true);
    dispatch(LogoutAction());
    localStorage.removeItem("token");
    history.push("/");
  };

  //prettier-ignore
  const isInvestigator = userRoleId === userRoles.investigator;
  const isPAS = userRoleId === userRoles.PAS;


  return (
    <>
      <div className="border-right" id="sidebar-wrapper">
        <div className="sidebar-heading">
          <img className="loginLogo" src={logoUma} alt="" />
        </div>

        <Nav
          className="list-group list-group-flush"
          onSelect={(selectedKey: any) => alert(`selected ${selectedKey}`)}
        >
          <div className="sidebar-sticky"></div>
          <Nav.Item className="list-group list-group-flush">
            <NavLink
              className="list-group-item list-group-item-action inactive"
              activeClassName="inactive"
              to={"/"}
            >
              <FontAwesomeIcon className="iconClass mr-3" icon={faHome} />
              <span>Inicio</span>
            </NavLink>
          </Nav.Item>
          <Nav.Item className="list-group list-group-flush">
            <NavLink
              className="list-group-item list-group-item-action inactive"
              activeClassName="active"
              to={`${ROUTES.ManageProfile}`}
            >
              <FontAwesomeIcon className="iconClass mr-3" icon={faUser} />
              <span>Perfil</span>
            </NavLink>
          </Nav.Item>

          <Nav.Item className="list-group list-group-flush">
            <NavLink
              className="list-group-item list-group-item-action inactive"
              activeClassName="active"
              to={ROUTES.Publications.all}
            >
              <FontAwesomeIcon className="iconClass mr-3" icon={faBookOpen} />
              <span>Publicaciones</span>
            </NavLink>
          </Nav.Item>

          {(isAdmin || isPAS) && (
            <Nav.Item className="list-group list-group-flush">
              <NavLink
                className="list-group-item list-group-item-action inactive"
                activeClassName="active"
                to={ROUTES.Users.all}
              >
                <FontAwesomeIcon className="iconClass mr-3" icon={faUsers} />
                <span>Usuarios</span>
              </NavLink>
            </Nav.Item>
          )}

          {(isAdmin) && (
                    <Nav.Item className="list-group list-group-flush">
                      <NavLink
                        className="list-group-item list-group-item-action inactive"
                        activeClassName="active"
                        to={ROUTES.Groups.all}
                      >
                        <FontAwesomeIcon className="iconClass mr-3" icon={faCoffee} />
                        <span>Líneas de investigación</span>
                      </NavLink>
                    </Nav.Item>

          )}

          {(isAdmin || isInvestigator) && (
            <Nav.Item className="list-group list-group-flush">
              <NavLink
                className="list-group-item list-group-item-action inactive"
                activeClassName="active"
                to={ROUTES.Projects.all}
              >
                <FontAwesomeIcon className="iconClass mr-3" icon={faBriefcase} />
                <span>Proyectos</span>
              </NavLink>
            </Nav.Item>
          )}
            <>
              <Nav.Item className="list-group list-group-flush">
                <NavLink
                  className="list-group-item list-group-item-action inactive"
                  activeClassName="active"
                  to={ROUTES.News.all}
                >
                  <FontAwesomeIcon className="iconClass mr-3" icon={faNewspaper} />
                  <span>Noticias</span>
                </NavLink>
              </Nav.Item>

            </>

            {(isAdmin) && (
          <Nav.Item className="list-group list-group-flush">
            <NavLink
              className="list-group-item list-group-item-action inactive"
              activeClassName="active"
              to={ROUTES.Degree.all}
            >
              <FontAwesomeIcon className="iconClass mr-3" icon={faBook} />
              <span>Titulaciones</span>
            </NavLink>
          </Nav.Item>
              )}

          {(isAdmin || isPAS) && ( 
            <Nav.Item className="list-group list-group-flush">
              <NavLink
                className="list-group-item list-group-item-action inactive"
                activeClassName="active"
                to={ROUTES.Subjects.all}
                >
                <FontAwesomeIcon className="iconClass mr-3" icon={faChalkboardTeacher} />
                <span>Asignaturas</span>
              </NavLink>
            </Nav.Item>
            )}



            {(isAdmin || isInvestigator) && ( 
            <Nav.Item className="list-group list-group-flush">
                <NavLink
                  className="list-group-item list-group-item-action inactive"
                  activeClassName="active"
                  to={ROUTES.TeachingResources.all}
                  >
                  <FontAwesomeIcon className="iconClass mr-3" icon={faPaperclip} />
                  <span>Recursos docentes</span>
                </NavLink>
              </Nav.Item>
            )}
            </Nav>

            
        <div className="logoutButton " onClick={logout}>
          <FontAwesomeIcon className="iconClass mr-3" icon={faSignOutAlt} />
          {!loading ? (
            <span>Cerrar sesión</span>
          ) : (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
          )}
        </div>
      </div>
    </>
  );
};
