/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import Axios from "axios";
import {
  create,
  getAll,
  getByGroup,
  getById,
  saveFile,
  update,
} from "../../../../utils/requests/dynamicReq";
import { paths } from "../../../../utils/paths";
import { FileUploader } from "../../../../components/FileUploader/FileUploader";
import { useDelete } from "../../../../utils/hooks/useDelete";
import { investigatorRoles } from "../../../../utils/roles";
import estrella from "../../../../assets/images/estrella.svg";
import estrellaAzul from "../../../../assets/images/estrellaSolid.svg";

import { Col, Form, InputGroup } from "react-bootstrap";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faStar, faTrash } from "@fortawesome/free-solid-svg-icons";
import { produce } from "immer";
import { requiredInputs, SwalError } from "../../../../utils/constants";
import { ROUTES } from "../../../../utils/routes";
import { useSelector } from "react-redux";
import { IStore } from "../../../../interfaces/IStore";
import { IUser } from "../../../../interfaces/IUser";
import { IGroups } from "../../../../interfaces/IGroups";
import { IPublication } from "../../../../interfaces/IPublication";
import moment from "moment";
import parse from "html-react-parser";

export const ManageGroups: FC = () => {
  let { investigatorId, isAdmin } = useSelector(
    ({ user }: IStore) => user as IUser
  );

  const { id }: any = useParams();
  const history = useHistory();
  const { deleteIt } = useDelete();

  const isMountedRef = useRef(false);
  const source = Axios.CancelToken.source();
  const {
    INVESTIGATORS,
    GROUPS,
    AREAS,
    investigatorGROUP,
    publicationsByGROUP,
    groupSelectPUBLICATION,
  } = paths;

  const [isCreate, setIsCreate] = useState(false);
  const [isIpUser, setIsIpUser] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  //Group form
  const [nameEs, setNameEs] = useState("");
  const [nameEn, setNameEn] = useState("");
  const [ips, setIps] = useState<any[]>([]);
  const [chosenIps, setChosenIps] = useState<any[]>([]);
  const [participants, setParticipants] = useState<any[]>([]);
  const [chosenParticipants, setChosenParticipants] = useState<any[]>([]);
  const [members, setMembers] = useState<string[]>([]);
  const [areas, setAreas] = useState<any[]>([]);
  const [chosenAreas, setChosenAreas] = useState<any[]>([]);
  const [image, setImage] = useState();
  const [url, setUrl] = useState("");
  const [descriptionEs, setDescriptionEs] = useState("");
  const [descriptionEn, setDescriptionEn] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [twitter, setTwitter] = useState("");
  const [publications, setPublications] = useState<IPublication[]>([]);
  const [selectedPublications, setSelectedPublications] = useState<
    IPublication[]
  >([]);
  const [displayPublications, setDisplayPublications] = useState<
    IPublication[]
  >([]);

  const [message, setMessage] = useState("");

  const [allowedEdit, setAllowedEdit] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    isMountedRef.current = true;
    checkIfIsEdit();
    return () => {
      isMountedRef.current = false;
      source.cancel();
    };
  }, []);

  const checkIfIsEdit = () => {
    const { pathname }: any = history.location;
    if (pathname.includes("crear")) {
      setIsCreate(true);
      loadDefaultData(true);
    } else {
      loadDefaultData(false);
      setIsCreate(false);
    }
  };

  const loadDefaultData = async (isCreate: boolean) => {
    try {
      let investigators = await getAll(INVESTIGATORS, source);
      let areas = await getAll(AREAS, source);
      if (!isCreate) {
        let publications = await getByGroup(publicationsByGROUP, id, source);
        setPublications(publications);
        reorderPublications(publications);
        setIsLoading(false);
      }

      if (isMountedRef.current) {
        setTimeout(() => filterAdmins(investigators), 250);
        setAreas(areas);
        setAllowedEdit(true);
        if (!isCreate) {
          loadGroup(areas);
        }
      }
    } catch (error) {
      console.log("HTTP call cancelled");
    }
  };

  const filterAdmins = (investigators: any[]) => {
    let newinvestigators = investigators.filter(
      (u) =>
        u.email !== "admin@admin.com" &&
        u.email !== "sombradoble@sombradoble.es"
    );
    filterInvestigators(newinvestigators);
    filterParticipants(newinvestigators);
  };

  const filterInvestigators = (investigators: any) => {
    const ipInvestigators = investigators.filter(
      ({ investigatorRole, investigationGroup }: any) =>
        investigatorRole?.id === investigatorRoles.ip && !investigationGroup
    );
    setIps(ipInvestigators);
  };

  const filterParticipants = (investigators: any) => {
    const ipParticipans = investigators.filter(
      ({ investigatorRole, investigationGroup }: any) =>
        investigatorRole?.id !== investigatorRoles.ip && !investigationGroup
    );
    setParticipants(ipParticipans);
  };

  const loadGroup = async (areas: any[]) => {
    try {
      const {
        nameEs,
        nameEn,
        areas,
        investigators,
        descriptionEs,
        descriptionEn,
        twitter,
        linkedin,
        image,
        url,
      } = await getById(GROUPS, id, source);
      if (isMountedRef.current) {
        setNameEs(nameEs);
        setNameEn(nameEn);
        setMembers(members);
        setDescriptionEs(descriptionEs);
        setDescriptionEn(descriptionEn);
        setTwitter(twitter);
        setLinkedin(linkedin);
        setImage(image);
        setUrl(url);

        setChosenAreas(areas);
        newAreas(areas, areas);

        filterMembers(investigators);
      }
    } catch (error) {
      console.log("HTTP call cancelled");
    }
  };

  const newAreas = (u: any[], areas: any[]) => {
    // eslint-disable-next-line array-callback-return
    u.map((r: any) => {
      setAreas((state) =>
        produce(state, (drafState) => drafState.filter(({ id }) => id !== r.id))
      );
    });
  };

  const filterMembers = (members: any[]) => {
    const ipInvestigators = members.filter(
      ({ investigatorRole }: any) =>
        investigatorRole?.id === investigatorRoles.ip
    );
    setChosenIps(ipInvestigators);

    
      (ipInvestigators?.some(({ id }) => id === investigatorId)) &&
      setIsIpUser(true);

    const participants = members.filter(
      ({ investigatorRole }: any) =>
        investigatorRole?.id !== investigatorRoles.ip
    );
    setChosenParticipants(participants);
  };

  //----------------

  //HANDLE PUBLICATIONS

  const reorderPublications = (publications: any[]) => {
    console.log(publications);
    let newPublications: any[] = [];
    publications.map(
      ({
        id,
        title,
        date,
        journal,
        url,
        authors,
        volPag,
        investigatorId,
        investigationGroupPublications,
        titleChapter
      }: // eslint-disable-next-line array-callback-return
      any) => {
        newPublications.push({
          id,
          title,
          date,
          journal,
          url,
          authors,
          volPag,
          investigatorId,
          titleChapter,
          selected: investigationGroupPublications[0]?.selected,
        });
      }
    );
    return filterSelectedPublications(newPublications);
  };

  const filterSelectedPublications = (publications: IPublication[]) => {
    console.log(publications);
    const selectedPublications = publications.filter(
      ({ selected }: IPublication) => selected === true
    );
    console.log(selectedPublications);
    setSelectedPublications(selectedPublications);
    const displayPublications = publications.filter(
      ({ selected }: IPublication) => selected === false
    );
    setDisplayPublications(displayPublications);
  };

  //----------------

  // HANDLE INVESTIGATORS
  const addInvestigator = (ip: any, investigator: string) => {
    ip = JSON.parse(ip);

    investigator === "IP"
      ? //add chosenIp
        setChosenIps((state) =>
          produce(state, (drafState) => {
            drafState.push(ip);
          })
        )
      : //add chosenParticipant
        setChosenParticipants((state) =>
          produce(state, (drafState) => {
            drafState.push(ip);
          })
        );

    //remove ip from list
    setIps((state) =>
      produce(state, (drafState) => drafState.filter(({ id }) => id !== ip.id))
    );

    //remove participant from list
    setParticipants((state) =>
      produce(state, (drafState) => drafState.filter(({ id }) => id !== ip.id))
    );
  };

  const removeInvestigator = (ip: any, investigator: string) => {
    if (isIpUser || isCreate || isAdmin) {
      investigator === "IP"
        ? // remove chosenIp
          setChosenIps((state) =>
            produce(state, (drafState) =>
              drafState.filter(({ id }) => id !== ip.id)
            )
          )
        : // remove chosenParticipan
          setChosenParticipants((state) =>
            produce(state, (drafState) =>
              drafState.filter(({ id }) => id !== ip.id)
            )
          );

      investigator === "IP"
        ? // add ip to list
          setIps((state) =>
            produce(state, (drafState) => {
              drafState.push(ip);
            })
          )
        : // add participant to list
          setParticipants((state) =>
            produce(state, (drafState) => {
              drafState.push(ip);
            })
          );
    }
  };

  //----------------

  // HANDLE AREAS
  const addArea = (area: any) => {

    area = JSON.parse(area);
     setChosenAreas((state) =>
          produce(state, (drafState) => {
            drafState.push(area);
          })
        )
      setAreas((state) =>
          produce(state, (drafState) =>
            drafState.filter(({ id }) => id !== area.id)
          )
        )
    }

  const removeArea = (area: any) => {
       //remove area from list
          setChosenAreas((state) =>
            produce(state, (drafState) =>
              drafState.filter(({ id }) => id !== area.id)
            )
          )
      // add area to list
          setAreas((state) =>
            produce(state, (drafState) => {
              drafState.push(area);
            })
          )
      
  };

  //----------------
  const handleSubmit = () => {
    const areaIds = selectAreasIds();
    const group: IGroups = buildGroup(areaIds);
    const investigatorIds = selectInvestigatorsIds();

    if (!nameEs || !descriptionEs || !chosenIps) {
      setMessage(requiredInputs);
      setTimeout(() => setMessage(""), 4000);
      return;
    }
    let question = isCreate ? "crear una" : "editar esta";
    Swal.fire({
      icon: "question",
      text: `¿Quieres ${question} línea de investigación?`,
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then(({ isConfirmed }) => {
      isConfirmed && handleConfirmation(group, investigatorIds, image);
    });
  };

  const handleConfirmation = (
    group: IGroups,
    investigatorIds: number[],
    image: any
  ) => {
    let createOrUpdate = isCreate
      ? create(GROUPS, group, source)
      : update(GROUPS, id, group, source);
    createOrUpdate
      .then(async ({ id: groupId }) => {
        create(`${investigatorGROUP}/${groupId}`, { investigatorIds }, source);
        let createOrUpdateText = isCreate ? "creada" : "editada";
        Swal.fire({
          icon: "success",
          text: `Línea de Investigación ${createOrUpdateText} correctamente.`,
        });
        if (typeof image !== "string") {
          const formData = new FormData();
          formData.append("image", image);
          await saveFile(GROUPS, groupId, "image", formData, source);
        }
        history.push(`${ROUTES.Groups.all}/${groupId}`);
        isMountedRef.current && setIsCreate(false);
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          text: SwalError,
        });
      });
  };

  const selectAreasIds = () => {
    let areaIds: number[] = [];
    chosenAreas.map(({ id }: any) => areaIds.push(id));
  
    return areaIds;
  };

  const selectInvestigatorsIds = () => {
    let investigatorIds: number[] = [];
    let members = [...chosenIps, ...chosenParticipants];
    members.map(({ id }: any) => investigatorIds.push(id));
 
    return investigatorIds;
  };

  const buildGroup = (
    areaIds: number[]
  ) => ({
    nameEs,
    nameEn,
    descriptionEs,
    descriptionEn,
    twitter,
    linkedin,
    areaIds,
    url,
  });

  //----------------

  //PUBLICATIONS

  const searchBar = (value: any) => {
    setDisplayPublications(
      displayPublications.filter(({ title }: any) =>
        title.toLowerCase().includes(value.toLowerCase())
      )
    );
    !value && setDisplayPublications(displayPublications);
  };

  const changeIcon = (isSelected: any) => {
    return !isSelected ? estrella : estrellaAzul;
  };

  const changeNotSelected = (index: number) => {
    if (displayPublications[index!].selected === false) {
      const newdisplayPublications = [...displayPublications];
      newdisplayPublications[index!].selected = true;
      setDisplayPublications(newdisplayPublications);
      setTimeout(() => {
        handleSubmitPublications();
      }, 200);
    } else if (displayPublications[index!].selected === true) {
      const newdisplayPublications = [...displayPublications];
      newdisplayPublications[index!].selected = false;
      setDisplayPublications(newdisplayPublications);
      setTimeout(() => {
        handleSubmitPublications();
      }, 200);
    }
  };

  const changeSelected = (index: number) => {
    if (selectedPublications[index!].selected === false) {
      const newSelectedPublications = [...selectedPublications];
      newSelectedPublications[index!].selected = true;
      setSelectedPublications(newSelectedPublications);
      setTimeout(() => {
        handleSubmitPublications();
      }, 200);
    } else if (selectedPublications[index!].selected === true) {
      const newSelectedPublications = [...selectedPublications];
      newSelectedPublications[index!].selected = false;
      setSelectedPublications(newSelectedPublications);
      setTimeout(() => {
        handleSubmitPublications();
      }, 200);
    }
  };

  //----------------
  const editDeleteButtons = () => {
    if (isCreate) {
      return (
        <button className="baseBtn" onClick={handleSubmit}>
          Crear Línea de Investigación
        </button>
      );
    }
    //if is update && logged user included as IP
    if (
      (!isCreate && chosenIps?.some(({ id }) => id === investigatorId)) ||
      isAdmin
    ) {
      return (
        <>
          <button className="baseBtn" onClick={handleSubmit}>
            Editar Línea de Investigación
          </button>
          <button
            className="smallBtn btn-danger ml-1"
            onClick={() => deleteIt(GROUPS, id, ROUTES.Groups.all, source)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </>
      );
    }
  };

  //----------------
  //HANDLE SELECTED PUBLICATIONS

  const handleSubmitPublications = () => {
    const newPublications = buildSelectedPublications();
  
    const count = countSelected(newPublications);
    if (count > 10) {
      Swal.fire({
        icon: "error",
        text: "Se pueden seleccionar un máximo de 10 publicaciones",
        customClass: {
          cancelButton: "buttonClass",
        },
      });
    } else {
      create(
        `${groupSelectPUBLICATION}/${id}/publication/`,
        newPublications,
        source
      )
        .then(() => {
          Swal.fire({
            icon: "success",
            text: `Selección actualizada correctamente.`,
          });
          loadDefaultData(false);
          history.push(`${ROUTES.Groups.all}/${id}`);
          isMountedRef.current && setIsCreate(false);
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            text: SwalError,
          });
        });
    }
  };

  const buildSelectedPublications = () => {
    let newSelectedPublications: any[] = [];
    let completePublications = [
      ...selectedPublications,
      ...displayPublications,
    ];
    completePublications.map(({ id: publicationId, selected }: any) =>
      newSelectedPublications.push({
        investigationGroupId: +id,
        publicationId,
        selected,
      })
    );
    return newSelectedPublications;
  };

  const countSelected = (totalPublications: any[]) => {
    const count = totalPublications.filter(({ selected }) => selected === true)
      .length;
    return count;
  };

  return (
    <div className="centerPage">
      <div className="container-fluid ">
        <div className="row">
          <div className="col d-flex justify-content-between">
            <div>
              <h3>Línea de investigación</h3>
            </div>
            <div>{editDeleteButtons()}</div>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12 text-center text-danger">
          <span>{message}</span>
        </div>
      </div>
      <div className="form-row mt-2  d-flex flex-start">
        {!isCreate ? (
          <div style={{ width: "50%", paddingLeft: "10%" }}>
            <FileUploader handleFile={setImage} image={image} />
          </div>
        ) : (
          <FileUploader handleFile={setImage} image={image} />
        )}
        <div className="col-6  d-flex flex-column  justify-content-center">
          <small>Nombre español</small>
          <input
            type="text"
            className="form-control"
            placeholder="Nombre español"
            value={nameEs}
            onChange={({ target: { value } }) => {
              setNameEs(value);
            }}
            disabled={!allowedEdit}
          />
          <small>Nombre inglés</small>
          <input
            type="text"
            className="form-control mt-4"
            placeholder="Nombre inglés"
            value={nameEn ? nameEn : ""}
            onChange={({ target: { value } }) => {
              setNameEn(value);
            }}
            disabled={!allowedEdit}
          />
        </div>
      </div>
      <div className="form-row mt-4">
        <div className="col">
          <small>Seleccionar investigador/es principal/es*</small>
          <select
            className="form-control input"
            value={""}
            onChange={({ target: { value } }) => {
              addInvestigator(value, "IP");
            }}
            disabled={!allowedEdit}
          >
            <option value={""}>Investigadores principales</option>
            {ips?.map((i: any) => (
              <option key={i.id} value={JSON.stringify(i)}>
                {`${i.surname1} ${i.surname2 ? i.surname2 : ""}, ${i.name}`}
              </option>
            ))}
          </select>
          {chosenIps?.map((ip: any) => (
            <span
              key={ip.id}
              style={{ cursor: "pointer" }}
              className="badge badge-info badge-blue-color mr-1 mt-3  "
              onClick={() => removeInvestigator(ip, "IP")}
            >
              {`${ip.name} ${ip.surname1} ${ip.surname2 ? ip.surname2 : ""}`} X
            </span>
          ))}
        </div>
        <div className="col">
          <small>Seleccionar investigadores participantes</small>
          <select
            className="form-control input"
            value={""}
            onChange={({ target: { value } }) => {
              addInvestigator(value, "PARTICIPANT");
            }}
            disabled={!allowedEdit}
          >
            <option value={""}>Investigadores participantes*</option>
            {participants?.map((i: any) => (
              <option key={i.id} value={JSON.stringify(i)}>
                {`${i.surname1} ${i.surname2 ? i.surname2 : ""}, ${i.name}`}
              </option>
            ))}
          </select>
          {chosenParticipants?.map((participant: any) => (
            <span
              key={participant.id}
              style={{ cursor: "pointer" }}
              className="badge badge-info badge-blue-color mr-1 mt-3  "
              onClick={() => removeInvestigator(participant, "PARTICIPANT")}
            >
              {`${participant.name} ${participant.surname1} ${
                participant.surname2 ? participant.surname2 : ""
              }`}
              X
            </span>
          ))}
        </div>
      </div>

     
      <div className="form-row mt-4">
        <div className="col">
          <small>Seleccionar areas</small>
          <select
            className="form-control input"
            value={""}
            onChange={({ target: { value } }) => {
              addArea(value);
            }}
            disabled={!allowedEdit}
          >
            <option value={""}>Areas</option>
            {areas?.map((i: any) => (
              <option key={i.id} value={JSON.stringify(i)}>
                {`${i.nameEs}`}
              </option>
            ))}
          </select>
          {chosenAreas?.map((area: any) => (
            <span
              key={area.id}
              style={{ cursor: "pointer" }}
              className="badge badge-info badge-blue-color mr-1 mt-3  "
              onClick={() => removeArea(area)}
            >
              {`${area.nameEs}`} X
            </span>
          ))}
        </div>
        <div className="col">
          <small>Web de la línea de investigación</small>
          <input
            type="text"
            className="form-control"
            placeholder="Url"
            value={url ? url : ""}
            onChange={({ target: { value } }) => {
              setUrl(value);
            }}
            disabled={!allowedEdit}
          />
        </div>
      </div>

      <div className="form-row mt-4">
      <div className="col">
            <small>LinkedIn</small>
            <input
              type="text"
              className="form-control"
              placeholder="Ej:https://www.linkedin.com/school/universidad-de-malaga/?originalSubdomain=es"
              value={linkedin ? linkedin : ""}
              onChange={({ target: { value } }) => {
                setLinkedin(value);
              }}
              disabled={!allowedEdit}
            />
          </div>
          <div className="col">
            <small>Twitter</small>
            <input
              type="text"
              className="form-control"
              placeholder="Ej:https://twitter.com/sfi_UMA"
              value={twitter ? twitter : ""}
              onChange={({ target: { value } }) => {
                setTwitter(value);
              }}
              disabled={!allowedEdit}
            />
          </div>
      </div>


      <div className="form-row mt-4">
        <div className="col">
          <small>Descripción español*</small>
          <textarea
            className="form-control textarea"
            placeholder="Descripción Español"
            rows={3}
            value={descriptionEs}
            onChange={({ target: { value } }) => {
              setDescriptionEs(value);
            }}
            disabled={!allowedEdit}
          />
        </div>
        <div className="col">
          <small>Descripción inglés</small>
          <textarea
            className="form-control textarea"
            placeholder="Descripción Inglés"
            rows={3}
            value={descriptionEn ? descriptionEn : ""}
            onChange={({ target: { value } }) => {
              setDescriptionEn(value);
            }}
            disabled={!allowedEdit}
          />
        </div>
      </div>

      {!isCreate && (
        <div className="row mt-4">
          <div className="col-12 ">
            <div className="col d-flex justify-content-between">
              <h5>Publicaciones seleccionadas</h5>
            </div>
            <table className="table table-hover mt-4">
              <tbody>
                {selectedPublications?.map(
                  (
                    { id, title, authors, date, selected }: IPublication,
                    index
                  ) => (
                    <tr key={index} className="pointer">
                      <td>
                        <div className="d-flex justify-content-between">
                          <div>
                            <h6>{parse(title)}</h6>
                            <span>
                              {authors} {","}{" "}
                              {moment(date).locale("es").format("YYYY")}
                            </span>
                          </div>

                          <img
                            style={{ width: "2%" }}
                            src={changeIcon(selected)}
                            alt=""
                            onClick={() => changeSelected(index)}
                          />
                        </div>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
            {isLoading && (
              <div className="d-flex justify-content-center">
                <div className="spinner-border mt-5" role="status" />
              </div>
            )}

            {!selectedPublications.length && !isLoading && (
              <div className="d-flex justify-content-center mb-3">
                <h5>-No hay publicaciones seleccionadas-</h5>
              </div>
            )}
          </div>
        </div>
      )}
      {!isCreate && (
        <div className="row mt-4">
          <Form.Group as={Col}>
            <InputGroup className="shadow">
              <InputGroup.Prepend>
                <InputGroup.Text className="inputGroup ">
                  <FontAwesomeIcon color="#0073B5" icon={faSearch} />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                placeholder="Buscar..."
                className="form-control"
                onChange={({ target: { value } }) => searchBar(value)}
              />
            </InputGroup>
          </Form.Group>
        </div>
      )}
      {!isCreate && (
        <div className="row mt-3">
          <div className="col-12 ">
            <h5>Publicaciones</h5>
            <table className="table table-hover">
              <tbody>
                {displayPublications?.map(
                  (
                    {  title, authors, date, selected, titleChapter }: IPublication,
                    index
                  ) => (
                    <tr key={index} className="pointer">
                      <td>
                      {titleChapter &&<h6>{parse(titleChapter)}</h6>}
                        <div className="d-flex justify-content-between">
                         {titleChapter ?<h6 className="text-secondary">{parse(title)}</h6> : <h6>{parse(title)}</h6>}
                          <img
                            style={{ width: "2%" }}
                            src={changeIcon(selected)}
                            alt=""
                            onClick={() => changeNotSelected(index)}
                          />
                        </div>
                        <p>
                          {authors} {","}{" "}
                          {moment(date).locale("es").format("YYYY")}
                        </p>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
            {isLoading && (
              <div className="d-flex justify-content-center">
                <div className="spinner-border mt-5" role="status" />
              </div>
            )}

            {!displayPublications.length && !isLoading && (
              <div className="d-flex justify-content-center mb-3">
                <h5>-No hay publicaciones-</h5>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
