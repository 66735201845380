import { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

//datepicker
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";

//ckeditor
import CKEditor from "ckeditor4-react";
import { editorConfig, SwalError } from "../../../../utils/constants";

//utils
import {
  create,
  getAll,
  getById,
    update,
} from "../../../../utils/requests/dynamicReq";
import { paths } from "../../../../utils/paths";
import {
  separateRoles,
  cleanUsers,
  uniqueUsers,
  buildInvestigatorSubject,
} from "../../../../utils/auxiliar/manageSubject";

//middleware
import produce from "immer";
import Swal from "sweetalert2";
import Axios from "axios";
import { validate } from "./validations";
import { ROUTES } from "../../../../utils/routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useDelete } from "../../../../utils/hooks/useDelete";
import { useSelector } from "react-redux";

registerLocale("es", es);

export const ManageSubject = () => {
  let { investigatorId, isAdmin } = useSelector(({ user }) => user);

  const { SUBJECTS, INVESTIGATORS, INVESTIGATOR_SUBJECT, DEGREES } = paths;
  const { id } = useParams();
  const history = useHistory();
  const { deleteIt } = useDelete();

  const [isCreate, setIsCreate] = useState(false);

  //userForm
  const [nameEs, setNameEs] = useState("");
  const [nameEn, setNameEn] = useState("");
  const [coordinators, setCoordinators] = useState([]);
  const [chosenCoordinators, setChosenCoordinators] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [chosenParticipants, setChosenParticipants] = useState([]);
  const [descriptionEs, setDescriptionEs] = useState("");
  const [descriptionEn, setDescriptionEn] = useState("");
  const [url, setUrl] = useState("");
  const [degreeId, setDegreeId] = useState("");
  const [degrees, setDegrees] = useState([]);

  const [allowedEdit, setAllowedEdit] = useState(false);

  const [message, setMessage] = useState("");

  const isMounted = useRef(false);
  const source = Axios.CancelToken.source();

  useEffect(() => {
    isMounted.current = true;
    const { pathname } = history.location;
    pathname.includes("crear") ? loadDefaultData() : loadEditData();
    loadStaticData();
    return () => {
      isMounted.current = false;
      source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadStaticData = async () => {
    try {
      let response = await getAll(DEGREES, source);
      isMounted.current && setDegrees(response);
    } catch (error) {
      console.log("HTTP call cancelled");
    }
  };

  const loadDefaultData = async () => {
    try {
      setIsCreate(true);
      let investigators = await getAll(INVESTIGATORS, source);
      if (isMounted.current) {
        setTimeout(() => filterAdmins(investigators), 250);
        setAllowedEdit(true);
        
      }
    } catch (error) {
      console.log("HTTP call cancelled");
    }
  };

  const filterAdmins = (investigators) => {
    let newinvestigators = investigators.filter(
      (u) =>
        u.email !== "admin@admin.com" &&
        u.email !== "sombradoble@sombradoble.es"
    );
    setCoordinators(newinvestigators);
    setParticipants(newinvestigators);
  };

  const loadEditData = async () => {
    try {
      let response = await getById(SUBJECTS, id, source);
      isMounted.current && buildObjToLoad(response);
    } catch (error) {
      console.log("HTTP call cancelled");
    }
  };

  const buildObjToLoad = async (p) => {
    setNameEn(p.nameEn);
    setNameEs(p.nameEs);
    setDescriptionEn(p.descriptionEn);
    setDescriptionEs(p.descriptionEs);
    setUrl(p.url);
    setDegreeId(p.degree.id);


    let investigators = await getAll(INVESTIGATORS, source);
    if (isMounted.current) {
      setCoordinatorsDisplay(p, investigators);
      setParticipantsDisplay(p, investigators);
      setAllowedEdit(true);
    }
  };

  const setCoordinatorsDisplay = async ({ investigatorSubjects }, investigators) => {
    
    const { coordinators, participants } = separateRoles(investigatorSubjects);
    setChosenCoordinators(coordinators);

    //get unassigned investigators by comparing with chosenIps
    let rawCoordinators = uniqueUsers(investigators, coordinators);
    let unassignedIps = uniqueUsers(rawCoordinators, participants);

    setCoordinators(cleanUsers(unassignedIps));
  };

  const setParticipantsDisplay = async ({ investigatorSubjects }, investigators) => {
    
    // TODO FIX THIS
    const { coordinators, participants } = separateRoles(investigatorSubjects);
    setChosenParticipants(participants);

    //get unassigned investigators by comparing with participants
    let rawInvs = uniqueUsers(investigators, participants);
    let unassignedInvs = uniqueUsers(rawInvs, coordinators);

    setParticipants(cleanUsers(unassignedInvs));
  };

  //----------------------------------------

  // HANDLE INVESTIGATORS
  const addInvestigator = (coordinator, investigator) => {
    coordinator = JSON.parse(coordinator);

    investigator === "COORDINATOR"
      ? //add chosenCoordinator
        setChosenCoordinators((state) =>
          produce(state, (drafState) => {
            drafState.push(coordinator);
          })
        )
      : //add chosenParticipant
        setChosenParticipants((state) =>
          produce(state, (drafState) => {
            drafState.push(coordinator);
          })
        );

    //remove coordinator from list
    setCoordinators((state) =>
      produce(state, (drafState) => drafState.filter(({ id }) => id !== coordinator.id))
    );

    //remove participant from list
    setParticipants((state) =>
      produce(state, (drafState) => drafState.filter(({ id }) => id !== coordinator.id))
    );
  };

  const removeInvestigator = (coordinator, investigator) => {
    investigator === "COORDINATOR"
      ? // remove chosenCoordinator
        setChosenCoordinators((state) =>
          produce(state, (drafState) => drafState.filter(({ id }) => id !== coordinator.id))
        )
      : // remove chosenParticipant
        setChosenParticipants((state) =>
          produce(state, (drafState) => drafState.filter(({ id }) => id !== coordinator.id))
        );

    // add coordinator to list
    setCoordinators((state) =>
      produce(state, (drafState) => {
        drafState.push(coordinator);
      })
    );

    // add participant to list
    setParticipants((state) =>
      produce(state, (drafState) => {
        drafState.push(coordinator);
      })
    );
  };


  const handleSubmit = () => {
    const subject = build();
    const notValid = validate(subject);
    if (notValid) {
      setMessage(notValid);
      setTimeout(() => setMessage(""), 4000);
      return;
    }
    let question = isCreate ? "crear un" : "editar este";
    Swal.fire({
      icon: "question",
      text: `¿Quieres ${question} asignatura?`,
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then(({ isConfirmed }) => isConfirmed && handleConfirmation(subject));
  };

  const handleConfirmation = (subject) => {
    let createOrUpdate = isCreate
      ? create(SUBJECTS, subject, source)
      : update(SUBJECTS, id, subject, source);
    createOrUpdate
      .then(async ({ id: subjectId }) => {
        const investigatorSubjects = buildInvestigatorSubject(
          chosenParticipants,
          chosenCoordinators,
          subjectId
        );
        await create(
          `${INVESTIGATOR_SUBJECT}/${subjectId}`,
          investigatorSubjects,
          source
        );
        let createOrUpdateText = isCreate ? "creado" : "editado";
        Swal.fire({
          icon: "success",
          text: `Asignatura ${createOrUpdateText} correctamente.`,
        });
        history.push(`${ROUTES.Subjects.all}/${subjectId}`);
        isMounted.current && setIsCreate(false);
      })
      .catch(() => Swal.fire({ icon: "error", text: SwalError }));
  };


  const build = () => {
    
    const subject ={
    nameEs,
    nameEn,
    descriptionEs,
    descriptionEn,
    url,
    degreeId: degreeId ? degreeId : null,
  }
  
  return subject;
};

  const editDeleteButtons = () => {
    if (isCreate) {
      return (
        <button className="baseBtn" onClick={handleSubmit}>
          Crear Asignatura
        </button>
      );
    }

      return (
        <>
          <button className="baseBtn" onClick={handleSubmit}>
            Editar Asignatura
          </button>
          <button
            className="smallBtn btn-danger ml-1"
            onClick={() => deleteIt(SUBJECTS, id, ROUTES.Subjects.all, source)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </>
      );
  };

  return (
    <div className="centerPage">
      <div className="container-fluid p-4">
        <div className="row">
          <div className="col d-flex justify-content-between">
            <div>
              <h3>Asignatura </h3>
            </div>
            <div>{editDeleteButtons()}</div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12 text-center text-danger">
            <span>{message}</span>
          </div>
        </div>
        
        <div className=" form-row mt-2">
          <div className="col-4 ">
            <small>Nombre español</small>
            <input
              type="text"
              className="form-control"
              placeholder="*Nombre español"
              value={nameEs}
              onChange={({ target: { value } }) => setNameEs(value)}
              disabled={!allowedEdit}

            />
          </div>
            <div className="col-4">
            <small>Nombre inglés</small>
            <input
              type="text"
              className="form-control"
              placeholder="Nombre inglés"
              maxLength="500"
              value={nameEn}
              onChange={({ target: { value } }) => setNameEn(value)}
              disabled={!allowedEdit}

            />
            </div>

            <div className="col-4">
            <small>*Titulación</small>
            <select
              className="form-control input"
              value={degreeId}
              onChange={({ target: { value } }) => {
                setDegreeId(+value);
              }}
              disabled={!allowedEdit}
            >
              <option value={""}>*Titulación</option>
              {degrees?.map(({ id, titleEs }) => (
                <option key={id} value={+id}>
                  {titleEs}
                </option>
              ))}
            </select>
          </div>

        </div>







        <div className="form-row mt-4">
          <div className="col">
            <select
              className="form-control input"
              value={""}
              onChange={({ target: { value } }) => addInvestigator(value, "COORDINATOR")}
              disabled={!allowedEdit}
            >
              <option value={""}>Coordinadores</option>
              {coordinators?.map((i) => (
                <option key={i.id} value={JSON.stringify(i)}>
                  {`${i.surname1} ${i.surname2 ? i.surname2 : ""}, ${i.name}`}
                </option>
              ))}
            </select>
            {chosenCoordinators?.map((coordinator) => (
              <span
                key={coordinator.id}
                style={{ cursor: "pointer" }}
                className="badge badge-info badge-blue-color mr-1 mt-3  "
                onClick={() => removeInvestigator(coordinator, "COORDINATOR")}
              >
                {`${coordinator.name} ${coordinator.surname1} ${coordinator.surname2 ? coordinator.surname2 : ""}`} X
              </span>
            ))}
          </div>
          <div className="col">
            <select
              className="form-control input"
              value={""}
              onChange={({ target: { value } }) =>
                addInvestigator(value, "PARTICIPANT")
              }
              disabled={!allowedEdit}
            >
              <option value={""}>Participantes</option>
              {participants?.map((i) => (
                <option key={i.id} value={JSON.stringify(i)}>
                  {`${i.surname1} ${i.surname2 ? i.surname2 : ""}, ${i.name}`}
                </option>
              ))}
            </select>
            {chosenParticipants?.map((participant) => (
              <span
                key={participant.id}
                style={{ cursor: "pointer" }}
                className="badge badge-info badge-blue-color mr-1 mt-3  "
                onClick={() => removeInvestigator(participant, "PARTICIPANT")}
              >
                {`${participant.name} ${participant.surname1} ${
                  participant.surname2 ? participant.surname2 : ""
                }`}
                X
              </span>
            ))}
          </div>
        </div>

        <div className="form-row mt-4">
          <div className="col">
            <small>Descripción en español</small>
            <CKEditor
              data={descriptionEs}
              config={editorConfig}
              onChange={({ editor }) => setDescriptionEs(editor.getData())}
              disabled={!allowedEdit}
            />
          </div>
        </div>

        <div className="form-row mt-4">
          <div className="col">
            <small>Descripción en inglés</small>
            <CKEditor
              data={descriptionEn}
              config={editorConfig}
              onChange={({ editor }) => setDescriptionEn(editor.getData())}
              disabled={!allowedEdit}
            />
          </div>
        </div>

        <div className="form-row mt-4">
          <div className="col">
            <input
              type="text"
              className="form-control"
              placeholder="Enlace de la asignatura (asegúrate de poner 'https://' o 'http://' y la web) Ejemplo: https://www.sombradoble.es"
              value={url}
              onChange={({ target: { value } }) => setUrl(value)}
              disabled={!allowedEdit}
            />
          </div>
        </div>

      </div>
    </div>
  );
};
