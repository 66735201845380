//ckeditor
import CKEditor from "ckeditor4-react";
import { ckInput, editorConfigShort } from "../../utils/constants";

//datepicker
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";

registerLocale("es", es);

export const CongressCommunicationForm = ({
  isIpUser,
  isCreate,
  isAdmin,
  title,
  setTitle,
  url,
  setUrl,
  date,
  setDate,
  authors,
  setAuthors,
  addAuthor,
  investigators,
  congress,
  setCongress,
  authorsId,
  removeAuthor,
  projects,
  setProjects,
  chosenProjectId,
  setChosenProjectId,
}) => {
  return (
    <>
      <div className="form-row mt-3">
        <small>*Título</small>
        <CKEditor
          className="form-control"
          style={ckInput}
          //ckEditor readOnly not working properly 
          // readOnly={!isIpUser && !isCreate && !isAdmin}
          type="inline"
          data={title}
          config={editorConfigShort}
          onChange={({ editor }) => setTitle(editor.getData())}
        />
      </div>

      <div className="form-row mt-3">
        <div className="col">
          <small>Urlo DOI. Si no se añade la url no se enlazará a la revista</small>
          <input
            type="text"
            className="form-control mt-2"
            placeholder="Url o DOI Ej: https://doi.org/10.1111/inr.12645"
            disabled={!isIpUser && !isCreate && !isAdmin}
            value={url}
            onChange={({ target: { value } }) => {
              setUrl(value);
            }}
          />
        </div>
        <div className="col">
          <small>*Fecha</small>
          <DatePicker
            className="form-control input mt-2"
            placeholderText="*Fecha"
            selected={date}
            onChange={(date) => setDate(date)}
            dateFormat="dd MMMM, yyyy"
            disabled={!isIpUser && !isCreate}
            isClearable
            locale="es"
            dropdownMode="select"
            showMonthDropdown
            showYearDropdown
            adjustDateOnChange
          />
        </div>
      </div>
      <div className="form-row mt-3">
        <div className="col">
          <small>*Autores</small>
          <input
            type="text"
            className="form-control mt-2"
            placeholder="Ej: Navas-Castillo J, Rivera-Bustamante R, Roumagnac P"
            disabled={!isIpUser && !isCreate && !isAdmin}
            value={authors}
            onChange={({ target: { value } }) => {
              setAuthors(value);
            }}
          />
        </div>
      </div>
      <div className="form-row mt-3">
        <div className="col-6">
          <small>*Seleccionar autores del departamento</small>
          <select
            className="form-control input mt-2"
            disabled={!isIpUser && !isCreate && !isAdmin}
            value={""}
            onChange={({ target: { value } }) => {
              addAuthor(value);
            }}
          >
            <option value={""}>Selecionar autores del departamento</option>
            {investigators.length &&
              investigators?.map((investigator, index) => (
                <option key={index} value={JSON.stringify(investigator)}>
                  {` ${investigator.surname1} ${investigator.surname2 ? investigator.surname2 : ""}, ${investigator.name}`}
                </option>
              ))}
          </select>
          {!!authorsId[0]?.id &&
            authorsId?.map((ip, i) => (
              <span
                key={i}
                style={{ cursor: "pointer" }}
                className="badge badge-info badge-blue-color mr-1 mt-3"
                onClick={() => removeAuthor(ip)}
              >
                {`${ip?.name} ${ip?.surname1} ${ip?.surname2 ? ip?.surname2 : ""}`} X
              </span>
            ))}
        </div>
        <div className="col-6">
          <small>Proyecto</small>
            <select
              className="form-control input mt-2"
              value={chosenProjectId}
              onChange={({ target: { value } }) => {
                setChosenProjectId(+value);
              }}
            >
              <option value={null}>Ninguno</option>
              {projects?.map(({ id, nameEs }) => (
                <option key={id} value={+id}>
                  {nameEs}
                </option>
              ))}
            </select>
          </div>
      </div>
      <div className="form-row mt-3">
        <div className="col">
          <small>Vol. página</small>
          <input
            type="text"
            className="form-control mt-2"
            placeholder="142:11-20"
            disabled={!isIpUser && !isCreate && !isAdmin}
            onChange={({ target: { value } }) => {
            }}
          />
        </div>
        <div className="col">
          <small>Congreso*</small>
          <input
            className="form-control mt-2"
            disabled={!isIpUser && !isCreate && !isAdmin}
            value={congress}
            placeholder="Ej: Plant Cell, Congress of herbology"
            onChange={({ target: { value } }) => {
              setCongress(value);
            }}
          />
        </div>
      </div>
    </>
  );
};
