export const ROUTES = {
  Login: "/",
  ManageProfile: "/perfil",
  Users: {
    all: "/usuarios",
    update: "/usuarios/:id",
    create: "/usuarios/crear",
  },
  Groups: {
    all: "/grupos",
    update: "/grupos/:id",
    create: "/grupos/crear",
  },
  Projects: {
    all: "/proyectos",
    update: "/proyectos/:id",
    create: "/proyectos/crear",
  },
  Subjects: {
    all: "/asignaturas",
    update: "/asignaturas/:id",
    create: "/asignaturas/crear",
  },
  Publications:{
    all:"/publicaciones",
    update: "/publicaciones/:id",
    create: "/publicaciones/crear",
  }, 
  News: {
    all: "/noticias",
    update: "/noticias/:id",
    create: "/noticias/crear",
  },
  Degree: {
    all: "/formacion",
    update: "/formacion/:id",
    create: "/formacion/crear",
  }, 
  TeachingResources: {
    all: "/teachingResources",
    update: "/teachingResources/:id",
    create: "/teachingResources/crear",
  },
};
